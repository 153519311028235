/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/resound/'], ['en', 'https://www.hear.com/hearing-aids/brands/resound/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/resound/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/resound/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "discover-resound",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#discover-resound",
    "aria-label": "discover resound permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Discover ReSound"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids have certainly come a long way from their humble origins. While the original hearing devices were big, bulky, and inefficient, ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "modern hearing aids"), " are continually pushing the limits of what’s possible in hearing healthcare. One ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid manufacturer"), " in particular, ReSound, is totally defining what a hearing aid can do."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Coming up, we’ll take a look at ReSound – past, present, and future – to help you better understand all of the unique hearing aids that this company has to offer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resounds-beginnings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resounds-beginnings",
    "aria-label": "resounds beginnings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resound’s Beginnings"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/resound-logo.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "On 1943, ReSound started on a mission: to create the world’s most advanced hearing aids so you can adapt to any environment for the best possible listening experience. Since their early years, ReSound has been the first to develop several new technologies, including the first 2.4GHz hearing aids and the world’s first Made for Apple® hearing aids that support direct sound streaming.\nThese days, ReSound is part of the GN Group, which is headquartered in Ballerup, Denmark. The GN Group boasts over 147 years of expertise in the audiology and audio sectors, which allows them to be a pioneer in smart audiology technology. Additionally, the networks created by the GN Group allow ReSound to harness the power of their sister companies’ technology to create the best in hearing aid performance.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-worlds-smartest-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-worlds-smartest-hearing-aids",
    "aria-label": "the worlds smartest hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The World’s Smartest Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/resound-smart-app.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "ReSound is dedicated to providing their customers with the most natural listening experience possible for a higher quality of life. With ReSound’s commitment to a natural listening experience, hearing aid users can spend more time enjoying a conversation and less time worrying about missing out on the action.\nReSound believes strongly in the concept of “Smart Hearing.” Through the four cornerstones of Smart Hearing – audiology, connectivity, apps, and design – ReSound not only helps people with hearing loss but empowers them to take back control over their experiences and communication. ReSound’s Smart Hearing technology brings sounds to life, connects seamlessly with other devices, has a personalized user interface, and features modern, durable designs for a life better lived.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-makes-resound-special",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-makes-resound-special",
    "aria-label": "what makes resound special permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What makes ReSound Special"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "ReSound’s current line of hearing aids runs the gamut from small, invisible-in-the-canal devices to those made specifically for children, but they all feature the same excellent ReSound technology. Let’s take a look at what makes ReSound’s hearing aids so special:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "binaural-directionality-iii",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-directionality-iii",
    "aria-label": "binaural directionality iii permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural Directionality III"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Binaural Directionality III harnesses the power of the ear-to-ear connection to allow hearing aid users to better balance sounds from front to back and side to side. This makes it easier for an individual to locate sounds and have a more natural listening experience every day."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "24-ghz-hearing-aid-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#24-ghz-hearing-aid-technology",
    "aria-label": "24 ghz hearing aid technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2.4 GHz Hearing Aid Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "ReSound started putting 2.4 GHz technology into hearing aids in 2010, and things have only improved since this first breakthrough. By using 2.4 GHz connections, ReSound provides higher quality direct audio streaming from any compatible device without any intermediary regulators simplifying your life and improving your listening environment."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "cleaning-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cleaning-technology",
    "aria-label": "cleaning technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cleaning Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unwanted background noise is easily distracting, which is why ReSound packs their hearing aids full of cleaning technology that reduces the impact of wind and feedback on your listening experience. Doing so makes it easier to focus on the world in front of you and tune out background noise. What could be better?"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "tinnitus-treatment-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tinnitus-treatment-technology",
    "aria-label": "tinnitus treatment technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tinnitus Treatment Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For those of us who suffer from tinnitus, the condition can be more than annoying – it can be debilitating. ReSound has answered the call of millions of tinnitus sufferers who are looking for alternative treatment options by offering built-in sound therapy technology in their hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "in-the-news",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-news",
    "aria-label": "in the news permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In the News"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/resound-device.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Although they have decades of experience in innovation, ReSound recently surprised the world of hearing healthcare by developing a whole new category of hearing aids: Premium Plus. The new ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/linx",
    className: "c-md-a"
  }, "ReSound LiNX Quattro™"), " offers a unique combination of technologies that can’t be found anywhere else.\nThese technologies include the most dynamic sound range available, which provides the fullest, most precise, and most vibrant sound profile for better speech understanding in any situation. Plus, the ReSound LiNX Quattro™ features rechargeable batteries with up to 30 hours of battery life and a user-friendly app that allows a hearing healthcare professional to adjust a hearing aid remotely, reducing the need for unnecessary clinic visits.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resound-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-hearing-aids",
    "aria-label": "resound hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ReSound-Magna.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "resound-magna",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-magna",
    "aria-label": "resound magna permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound Magna"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hear.com offers the ReSound Magna, a superb hearing technology solution. This digital super-power BTE (behind-the-ear) hearing aid fits comfortably behind your ear and sends the amplified sound into your ear through a custom-made earmold. It has 144 dB SPL maximum power output and 86 dB maximum gain. What that specification means is that the aid will make speech audible to the greatest extent possible for severe to profound hearing loss."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/magna",
    className: "c-md-a"
  }, "Read More"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ReSound-Vea.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "resound-vea",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-vea",
    "aria-label": "resound vea permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound Vea"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound Vea line of hearing aids is home to six different models with three different levels of technology, so there’s something in the Vea family for everyone. Get ready to meet the hearing aid that can keep you hearing well, day in and day out."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/vea",
    className: "c-md-a"
  }, "Read More"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ReSound-Enya.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "resound-enya",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-enya",
    "aria-label": "resound enya permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound Enya"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to the ReSound Enya hearing aid and it’s high-tech features, anything is possible. Hearing loss shouldn’t hold you back, so get ready to meet the ReSound Enya hearing aid – your key to a life well lived."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/enya",
    className: "c-md-a"
  }, "Read More"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/resound-device.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "resound-linx",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-linx",
    "aria-label": "resound linx permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound LiNX"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound LiNX 3D and ReSound LiNX Quattro bring to you one of the best solutions in the industry. You’ll love the sound quality and noise reduction, rechargeable battery options, streaming, and phone app.\nModels that fit behind the ear come in multiple sizes and seventeen attractive colors, so you’ll find a good match for your hearing loss with pleasing cosmetic results. If you prefer a tiny in-the-ear model, the LiNX 3D offers discrete options. These are not your grandmother’s bulky hearing aids."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/linx",
    className: "c-md-a"
  }, "Read More"))), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
